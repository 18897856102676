<template>
    <div class="root_social">
        <div class="container ner_responesize">
            <div class="row">
                <div class="leftadv_social" :class="classAc">
                    <a :href="linkf8bet" target="_blank">
                        <img src="../assets/images/t.gif" alt="">
                    </a>
                </div>
                <div class="rightadv_social" :class="classAc">
                    <a :href="linkf8bet" target="_blank">
                        <img src="../assets/images/p.gif" alt="">
                    </a>
                </div>
                <div class="col-md-12 col-sm-12 col-xs-12 left_social">
                    <div class="wp_social">
                        <div class="wraper_md">
                            <div class="img_scal">
                                <router-link v-bind:to="{ name: 'Info_nm', params: { id: iduser } }">
                                    <img src="../assets/images/avatar.jpg" v-if="img == 'null'" alt="">
                                    <img :src="link_web + img" alt="" v-else>
                                </router-link>
                            </div>
                            <div class="input_scal" @click="showMdClick">
                                Đăng bài viết
                            </div>
                        </div>
                        <div class="wp_item_social" :id="'wp_item_social' + item.id" v-for="(item, index) in listPost" :key="index">
                            <div class="item_social">
                                <div class="info_user_social">
                                    <div class="img_user_social">
                                        <img :src="link_web + item.hinh_anh" alt="">
                                    </div>
                                    <div class="name_user_social">
                                        <p class="name_us_sca">{{ item.fullname }}</p>
                                        <p class="time_social">{{ formatTime(item.created_at) }}</p>
                                    </div>
                                    <div class="edit_deletePt" v-if="item.user_id == iduser">
                                        <svg @click="toggleContent(index)" viewBox="0 0 20 20" width="20" height="20" fill="currentColor" class="xfx01vb x1lliihq x1tzjh5l x1k90msu x2h7rmj x1qfuztq" style="--color: var(--secondary-icon);"><g fill-rule="evenodd" transform="translate(-446 -350)"><path d="M458 360a2 2 0 1 1-4 0 2 2 0 0 1 4 0m6 0a2 2 0 1 1-4 0 2 2 0 0 1 4 0m-12 0a2 2 0 1 1-4 0 2 2 0 0 1 4 0"></path></g></svg>
                                        <div class="menu_tv" v-if="openIndex === index">
                                            <p class="editPost" @click="editPost(index)">Sửa bài viết</p>
                                            <p class="deletePost" @click="deletePost(item.id)">Xóa bài viết</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="content_social">
                                    <div class="text_social">
                                        <div class="status_social">
                                            <p>{{ item.content }}</p>
                                        </div>
                                        <div class="img_video_social">
                                            <div class="media-container">
                                                <keep-alive>
                                                <Fancybox v-if="item.video || item.images" :key="item.id" :options="{ Carousel: { infinite: false, }, }" class="pd_row row" :class="'columItem' + (Number(imagesArray(item.video).length) + Number(imagesArray(item.images).length))">
                                                    <div v-if="item.video != ''" class="item_imgpost">
                                                        <a data-fancybox="gallery" :href="link_web + item.video">
                                                            <video controls autoplay muted webkit-playsinline playsinline>
                                                                <source :src="link_web + item.video" type="video/mp4">
                                                            </video>
                                                        </a>
                                                    </div>
                                                    <div class="item_imgpost" v-for="(img, index1) in imagesArray(item.images)" :key="index1">
                                                        <a data-fancybox="gallery" :href="link_web + img">
                                                            <img :src="link_web + img">
                                                        </a>
                                                    </div>
                                                </Fancybox>
                                                </keep-alive>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="wp_like_add_post">
                                    <div class="wp_ptstart">
                                        <div class="number_allpt">
                                            <span @click="listLikePt(index)">
                                                <img src="../assets/images/like.svg" alt="">
                                                <span>{{ formatNumber(item.number_like) }}</span>
                                            </span>
                                        </div>
                                        <div class="comment_post">
                                            <span @click="commentShow(index)">{{ formatNumber(item.comment.length) }} <span>bình luận</span></span>
                                        </div>
                                    </div>
                                    <div class="like_com_btn">
                                        <div class="row">
                                            <div class="col-md-6 col-xs-6 col-sm-6 lf_btn">
                                                <div class="btn_post">
                                                    <button :class="{active: isLikedByUser(item)}" @click="likePost(item.id)">
                                                        {{ isLikedByUser(item) ? 'Bỏ thích' : 'Thích' }}
                                                    </button>
                                                </div>
                                            </div>
                                            <div class="col-md-6 col-xs-6 col-sm-6 rg_btn">
                                                <div class="btn_post">
                                                    <button @click="commentShow(index)">Bình luận</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- form edit post -->
                                <div class="modal-login" v-if="editingIndex === index">
                                    <div class="overlay" @click="closeModal"></div>
                                    <div class="modal">
                                        <div class="wp-modal-login">
                                            <div class="header-modal">
                                                <ul class="list_menu_modal">
                                                    <li class="img_modal"><img src="../assets/images/logo.png"></li>
                                                    <li class="active">Sửa bài viết</li>
                                                </ul>
                                                <button class="close-modal_login" @click="closeModal"><img src="../assets/images/close_md.png" alt=""></button>
                                            </div>
                                            <div class="content-modal_tab">
                                                <div>
                                                    <div class="wraper_if">
                                                        <div class="img_if">
                                                            <img src="../assets/images/avatar.jpg" v-if="img == 'null'" alt="">
                                                            <img :src="link_web + img" alt="" v-else>
                                                        </div>
                                                        <div class="input_if">
                                                            {{ fullname }}
                                                        </div>
                                                    </div>
                                                    <form action="" method="post" id="frm_postEdit" class="frmEdit_post" @submit="savePostEdit">
                                                        <div class="wp_frm_post">
                                                            <textarea v-model="editedPost.content" id="contentPtEd" cols="30" rows="3" placeholder="Nội dung"></textarea>
                                                        </div>
                                                        <div class="img_video_add">
                                                            <div class="imgadd_pt" v-if="editedPost.images.length > 0">
                                                                <p class="title_show">Hình ảnh</p>
                                                                <div class="row img_postadd">
                                                                    <div class="col-md-3 col-xs-3 col-sm-3 wp_imvd" v-for="(image, imgIndex) in editedPost.images" :key="imgIndex">
                                                                        <div class="img_showpt">
                                                                            <img :src="formatImageUrl(image.preview)" class="preview-img" />
                                                                            <span @click="removeImageEd(imgIndex)">x</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="videoadd_pt" v-if="editedPost.video">
                                                                <p class="title_show">Video</p>
                                                                <div class="row img_postadd">
                                                                    <div class="col-md-4 col-xs-4 col-sm-4 wp_imvd">
                                                                        <div class="img_showpt">
                                                                            <video :src="formatVideoUrl(editedPost.video)" controls class="preview-video"></video>
                                                                            <span @click="removeVdEd()">x</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        
                                                        <div class="img_video_btn">
                                                            <div class="img_btn">
                                                                <label for="imageUploadEd" class="upload-btn">
                                                                    <img src="../assets/images/btnig.png" alt="Upload" class="icon" />
                                                                    <span>Thêm ảnh</span>
                                                                </label>
                                                                <input type="file" id="imageUploadEd" accept="image/*" multiple @change="handleImageUpload" hidden />
                                                            </div>
                                                            <div class="video_btn">
                                                                <label for="videoUploadEd" class="upload-btn">
                                                                    <img src="../assets/images/vd.png" alt="Upload" class="icon" />
                                                                    <span>Thêm video</span>
                                                                </label>
                                                                <input type="file" id="videoUploadEd" multiple accept="video/*" @change="handleVideoUpload" hidden />
                                                            </div>
                                                        </div>
                                                        <div class="btn_sb_addpt">
                                                            <button type="submit">Lưu</button>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- end form edit post -->
                                <!-- form comment post -->
                                <div class="modal-login" v-if="commentIndex === index">
                                    <div class="overlay" @click="closeModalCm"></div>
                                    <div class="modal">
                                        <div class="wp-modal-login">
                                            <div class="header-modal">
                                                <ul class="list_menu_modal">
                                                    <li class="img_modal"><img src="../assets/images/logo.png"></li>
                                                    <li class="active">Bình luận bài viết</li>
                                                </ul>
                                                <button class="close-modal_login" @click="closeModalCm"><img src="../assets/images/close_md.png" alt=""></button>
                                            </div>
                                            <div class="content-modal_tab">
                                                <div>
                                                    <div class="root_commentMd">
                                                        <div class="info_user_social info_user_socialMd">
                                                            <div class="img_user_social">
                                                                <img :src="link_web + listComment.hinh_anh" alt="">
                                                            </div>
                                                            <div class="name_user_social">
                                                                <p class="name_us_sca">{{ listComment.fullname }}</p>
                                                                <p class="time_social">{{ formatTime(listComment.created_at) }}</p>
                                                            </div>
                                                        </div>
                                                        <div class="content_social">
                                                            <div class="text_social">
                                                                <div class="status_social status_socialMd">
                                                                    <p>{{ listComment.content }}</p>
                                                                </div>
                                                                <div class="img_video_social">
                                                                    <div class="media-container">
                                                                        <keep-alive>
                                                                            <Fancybox v-if="listComment.video || listComment.images" :key="listComment.id" :options="{ Carousel: { infinite: false, }, }" class="row" :class="'columItem' + (Number(listComment.lgVd) + Number(listComment.images.length))">
                                                                                <div v-if="listComment.video != ''" class="item_imgpost item_imgpostMd">
                                                                                    <a data-fancybox="gallery" :href="link_web + listComment.video">
                                                                                        <video controls autoplay muted webkit-playsinline playsinline>
                                                                                            <source :src="link_web + listComment.video" type="video/mp4">
                                                                                        </video>
                                                                                    </a>
                                                                                </div>
                                                                                <div class="item_imgpost item_imgpostMd" v-for="(img, index1) in listComment.images" :key="index1">
                                                                                    <a data-fancybox="gallery" :href="link_web + img">
                                                                                        <img :src="link_web + img">
                                                                                    </a>
                                                                                </div>
                                                                            </Fancybox>
                                                                        </keep-alive>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="wp_like_add_post wp_like_add_postmd">
                                                            <div class="wp_ptstart wp_ptstartMd">
                                                                <div class="number_allpt">
                                                                    <img src="../assets/images/like.svg" alt="">
                                                                    <span>{{ formatNumber(listComment.number_like) }}</span>
                                                                </div>
                                                                <div class="comment_post">
                                                                    {{ formatNumber(listComment.comment.length) }} bình luận
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="commentList_fe" v-if="listComment.comment.length > 0">
                                                            <div class="itemComment_list" v-for="(comment, indexCm) in listComment.comment" :key="indexCm">
                                                                <div class="imgCommentUs">
                                                                    <img src="../assets/images/avatar.jpg" v-if="comment.hinh_anh == null" alt="">
                                                                    <img :src="link_web + comment.hinh_anh" alt="" v-else>
                                                                </div>
                                                                <div class="nameVscontentCm">
                                                                    <div>
                                                                        <h3>{{ comment.fullname }}</h3>
                                                                        <p>{{ comment.content }}</p>
                                                                    </div>
                                                                </div>
                                                                <div class="timeRep_cm">
                                                                    <p>{{ formatTime(comment.created_at) }} <span @click="replyComment(comment.id)">Phản hồi</span> <span v-if="iduser == comment.user_id" @click="deleteComment(comment.post_id, comment.id, '0')">Xóa</span></p>
                                                                </div>
                                                                <div class="root_replyCm" v-if="comment.reply.length > 0">
                                                                    <div class="itemRepCm" v-for="(reply, indexRl) in comment.reply" :key="indexRl">
                                                                        <div class="imgCommentUs">
                                                                            <img :src="link_web + reply.hinh_anh" alt="">
                                                                        </div>
                                                                        <div class="nameVscontentCm">
                                                                            <div>
                                                                                <h3>{{ reply.fullname }}</h3>
                                                                                <p>{{ reply.content }}</p>
                                                                            </div>
                                                                        </div>
                                                                        <div class="timeRep_cm">
                                                                            <p>{{ formatTime(reply.created_at) }} <span v-if="iduser == reply.user_id" @click="deleteComment(reply.post_id, reply.comment_id, reply.id)">Xóa</span></p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <form action="" method="post" id="frm_postCm" class="frmEdit_postCm" @submit="sendComment">
                                                        <div class="root_imgIpCm">
                                                            <div class="img_Cmlf">
                                                                <img src="../assets/images/avatar.jpg" v-if="img == 'null'" alt="">
                                                                <img :src="link_web + img" alt="" v-else>
                                                            </div>
                                                            <div class="input_Cm">
                                                                <input type="hidden" class="idPostCm" :value="listComment.id">
                                                                <input type="hidden" class="idPostRep" value="0">
                                                                <input type="text" class="inputCmIn" @blur="handleBlur" v-model="commentTxt" placeholder="Viết bình luận">
                                                                <div class="btn_sb_cm">
                                                                    <button type="submit" id="sendButton"></button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- end form comment post -->
                                
                                <!-- form list Like post -->
                                <div class="modal-login" v-if="likeIndex === index && listlike.length > 0">
                                    <div class="overlay" @click="closeMdListLike"></div>
                                    <div class="modal">
                                        <div class="wp-modal-login">
                                            <div class="header-modal">
                                                <ul class="list_menu_modal">
                                                    <li class="img_modal"><img src="../assets/images/logo.png"></li>
                                                    <li class="active">Danh sách lượt thích</li>
                                                </ul>
                                                <button class="close-modal_login" @click="closeMdListLike"><img src="../assets/images/close_md.png" alt=""></button>
                                            </div>
                                            <div class="content-modal_tab">
                                                <div>
                                                    <div class="root_listLike">
                                                        <div class="userLike" v-for="(itemLk, indexLk) in listlike" :key="indexLk">
                                                            <div class="imgLike">
                                                                <img :src="link_web + itemLk.image" alt="">
                                                            </div>
                                                            <div class="nameLike">
                                                                <h3>{{ itemLk.name }}</h3>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- end form list like post -->
                            </div>
                        </div>
                        <!-- modal add post -->
                        <div id="modal-example" class="modal-login">
                            <div class="overlay" v-if="showmodal" @click="showmodal = false"></div>
                            <div class="modal" v-if="showmodal">
                                <div class="wp-modal-login">
                                    <div class="header-modal">
                                        <ul class="list_menu_modal">
                                            <li class="img_modal"><img src="../assets/images/logo.png"></li>
                                            <li class="active">Tạo bài viết</li>
                                        </ul>
                                        <button class="close-modal_login" @click="showmodal = false"><img src="../assets/images/close_md.png" alt=""></button>
                                    </div>
                                    <div class="content-modal_tab">
                                        <div>
                                            <div class="wraper_if">
                                                <div class="img_if">
                                                    <img src="../assets/images/avatar.jpg" v-if="img == 'null'" alt="">
                                                    <img :src="link_web + img" alt="" v-else>
                                                </div>
                                                <div class="input_if">
                                                    {{ fullname }}
                                                </div>
                                            </div>
                                            <form action="" method="post" id="frm_post" class="frmEdit_post" @submit="addPost">
                                                <div class="wp_frm_post">
                                                    <textarea v-model="content" id="contentPt" cols="30" rows="3" placeholder="Bạn muốn đăng gì?"></textarea>
                                                </div>
                                                <div class="img_video_add">
                                                    <div class="imgadd_pt" v-if="imageList.length > 0">
                                                        <p class="title_show">Hình ảnh</p>
                                                        <div class="row img_postadd">
                                                            <div class="col-md-3 col-xs-3 col-sm-3 wp_imvd" v-for="(img, index) in imageList" :key="index">
                                                                <div class="img_showpt">
                                                                    <img :src="img.preview" class="preview-img" />
                                                                    <span @click="removeImage(index)">x</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="videoadd_pt" v-if="videoList.length > 0">
                                                        <p class="title_show">Video</p>
                                                        <div class="row img_postadd">
                                                            <div class="col-md-4 col-xs-4 col-sm-4 wp_imvd" v-for="(video, index) in videoList" :key="index">
                                                                <div class="img_showpt">
                                                                    <video :src="video.preview" controls class="preview-video"></video>
                                                                    <span @click="removeVideo(index)">x</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="img_video_btn">
                                                    <div class="img_btn">
                                                        <label for="imageUpload" class="upload-btn">
                                                            <img src="../assets/images/btnig.png" alt="Upload" class="icon" />
                                                            <span>Thêm ảnh</span>
                                                        </label>
                                                        <input type="file" id="imageUpload" accept="image/*" multiple @change="handleFiles" hidden />
                                                    </div>
                                                    <div class="video_btn">
                                                        <label for="videoUpload" class="upload-btn">
                                                            <img src="../assets/images/vd.png" alt="Upload" class="icon" />
                                                            <span>Thêm video</span>
                                                        </label>
                                                        <input type="file" id="videoUpload" multiple accept="video/*" @change="handleVideos" hidden />
                                                    </div>
                                                </div>
                                                <div class="btn_sb_addpt">
                                                    <button type="submit">Đăng</button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- end modal add post -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import $ from 'jquery'
import Fancybox from './Fancybox.vue'
export default {
    name: 'Social_nm',
    data () {
        return {
            token: window.localStorage.getItem('token'),
            img: window.localStorage.getItem('img'),
            iduser: window.localStorage.getItem('numberus'),
            fullname: '',
            listPost: [],
            page: 1,
            limit: 20,
            hasMore: true,
            isLoading: false,
            other: [],
            showmodal: false,
            imageList: [],
            videoList: [],
            content: '',
            openIndex: null,
            editingIndex: null,
            editedPost: { id: '', content: "", images: [], video: ''  },
            commentIndex: null,
            lenghtVd: 0,
            listComment: { id: '', fullname: '', hinh_anh: '', content: "", images: [], video: '', created_at: ''  },
            commentTxt: '',
            idReply: '',

            likeIndex: null,
            listlike: [],
            imgaddUserpost: '',
            linkf8bet: '',
            classAc: ''
        }
    },
    components: {
        Fancybox
    },
    created () {
        this.getListPost()
        this.getListInfo()
        window.addEventListener("scroll", this.handleScroll);
        window.addEventListener("scroll", this.handleScrolls);
        this.getInfoLink()
    },
    beforeUnmount() {
        window.removeEventListener("scroll", this.handleScroll);
        window.removeEventListener("scroll", this.handleScrolls);
    },
    methods: {
        getInfoLink () {
            this.axios.get(this.api_previewLink + '?domain=' + this.url_param).then((response) => {
                this.linkf8bet = response.data.data.list_data.banner
            })
        },
        handleScrolls () {
            if (window.scrollY > 235) {
                this.classAc = 'active'
            }else{
                this.classAc = ''
            }
        },
        listLikePt(index) {
            this.likeIndex = index;
            this.listlike = this.listPost[index].liked_users;
        },
        closeMdListLike() {
            this.likeIndex = null;
            this.listlike = []
        },
        editPost(index) {
            this.openIndex = null
            this.editingIndex = index;
            let post = this.listPost[index];
            this.editedPost = {
                ...post,
                images: post.images.split(";").filter(img => img),
                video: post.video
            };
            this.editedPost.images = this.editedPost.images.map(image => {
                return { 
                    preview: image
                };
            });
        },
        closeModal() {
            this.editingIndex = null;
            this.editedPost = { id: '', content: "", images: [], video: '' };
        },
        removeImageEd(imgIndex) {
            this.editedPost.images.splice(imgIndex, 1);
            this.updateFileInputEd()
        },
        removeVdEd() {
            this.editedPost.video = null;
        },
        handleImageUpload(event) {
            const files = Array.from(event.target.files);
            files.forEach((file) => {
                const reader = new FileReader();
                reader.onload = (e) => {
                    this.editedPost.images.push({
                        path: file.name,
                        file: file,
                        preview: e.target.result
                    });
                    this.updateFileInputEd();
                };
                reader.readAsDataURL(file);
            });
            event.target.value = "";
        },
        handleVideoUpload(event) {
            const file = event.target.files[0];
            if (file) {
                const reader = new FileReader();
                reader.onload = (e) => {
                this.editedPost.video = e.target.result;
                };
                reader.readAsDataURL(file);
            }
        },
        formatImageUrl(image) {
            if (typeof image !== 'string') {
                return '';
            }
            if (image.startsWith("data:image") || image.startsWith("http://") || image.startsWith("https://")) {
                return image;
            }
            return this.link_web + image;
        },
        formatVideoUrl(video) {
            if (video.startsWith("data:video") || video.startsWith("http")) {
                return video;
            }
            return this.link_web + video;
        },
        updateFileInputEd() {
            const fileInput = document.getElementById("imageUploadEd");
            if (fileInput) {
                const dataTransfer = new DataTransfer();
                this.editedPost.images.forEach((img) => {
                if (img.file) {
                    dataTransfer.items.add(img.file);
                }
                });
                fileInput.files = dataTransfer.files;
            }
        },
        savePostEdit (e) {
            e.preventDefault()
            $('.loading_show').show().fadeIn(10)
            const formData = new FormData();
            formData.append('post_id', this.editedPost.id);
            formData.append('content', this.editedPost.content);
            let videoEd = document.getElementById('videoUploadEd').files
            if(videoEd.length > 0){
                formData.append('video[]', videoEd[0]);
            }else{
                if(this.editedPost.video == null){
                    formData.append('delete_video', 1);
                }
            }
            if (this.editedPost.images.length > 0) {
                const previews = this.editedPost.images.filter(img => !img.file).map(img => img.preview);
                for (let i = 0; i < previews.length; i++) {
                    formData.append('image_old[]', previews[i]);
                }
            }
            let fileImEd = document.getElementById('imageUploadEd').files
            
            if (fileImEd && fileImEd.length > 0) {
                for (let i = 0; i < fileImEd.length; i++) {
                    formData.append('images[]', fileImEd[i]);
                }
            }
            this.axios.post(this.api_editpost, 
                formData,
                {
                    headers: { Authorization: "Bearer " + this.token }
                }
            ).then(res => {
                $('#loading_show').css('display', 'none')
                let edId = res.data.data.id
                let edContent = res.data.data.content
                let edImg = res.data.data.images
                let edVideo = res.data.data.video
                let edUpdated = res.data.data.updated_at

                const postIndex = this.listPost.findIndex(post => post.id == edId);
                if (postIndex !== -1) {
                    this.listPost[postIndex].content = edContent;
                    this.listPost[postIndex].images = edImg;
                    this.listPost[postIndex].video = edVideo;
                    this.listPost[postIndex].updated_at = edUpdated;
                }
                this.$swal({
                    position: 'top-end',
                    icon: 'success',
                    title: res.data.message,
                    showConfirmButton: false,
                    timer: 4000
                })
                this.editingIndex = null;
                this.editedPost = { content: "", images: [], video: '' };
            }).catch(e => {
                $('#loading_show').css('display', 'none')
                this.$swal({
                    position: 'top-end',
                    icon: 'error',
                    title: e.response.data.message,
                    showConfirmButton: false,
                    timer: 3000
                })
            })
        },
        commentShow(index) {
            this.commentIndex = index;
            let post = this.listPost[index];
            if (this.listPost[index].video) {
                this.lenghtVd = 1
            }
            this.listComment = {
                ...post,
                id: post.id,
                fullname: post.fullname,
                hinh_anh: post.hinh_anh,
                content: post.content,
                images: post.images.split(";").filter(img => img),
                video: post.video,
                created_at: post.created_at,
                lgVd: this.lenghtVd
            };
            setTimeout(() => {
                $('.inputCmIn').focus()
            }, 300);
        },
        closeModalCm () {
            this.commentIndex = null;
            this.listComment = { id: '', fullname: '', hinh_anh: '', content: "", images: [], video: '', created_at: ''  }
        },
        sendComment (e) {
            e.preventDefault()
            $('.loading_show').show().fadeIn(10)
            let idPt = $('.idPostCm').val()
            let idRl = $('.idPostRep').val()
            this.axios.post(this.api_comment, 
                {
                    post_id: idPt,
                    content: this.commentTxt,
                    comment_id: idRl
                },
                {
                    headers: { Authorization: "Bearer " + this.token }
                }
            ).then(res => {
                $('#loading_show').css('display', 'none')
                const postIndex = this.listPost.findIndex(post => post.id == res.data.data.post_id);
                if (postIndex !== -1) {
                    if(this.img == 'null'){
                        this.imgaddUserpost = 'upload/post_fe/images/female-avatar.jpg'
                    }else{
                        this.imgaddUserpost = this.img
                    }
                    let arrCm = {
                        id: res.data.data.id,
                        post_id: res.data.data.post_id,
                        user_id: res.data.data.user_id,
                        comment_id: res.data.data.comment_id,
                        hinh_anh: this.imgaddUserpost,
                        fullname: res.data.data.name,
                        content: res.data.data.content,
                        number_like: 0,
                        created_at: res.data.data.created_at,
                        updated_at: res.data.data.created_at,
                        reply: []
                    }
                    if(res.data.data.comment_id == 0){
                        this.listPost[postIndex].comment.unshift(arrCm);
                    }else{
                        const postIndexCm = this.listPost[postIndex].comment.findIndex(post => post.id == res.data.data.comment_id);
                        if (postIndex !== -1) {
                            this.listPost[postIndex].comment[postIndexCm].reply.unshift(arrCm);
                        }
                    }
                }
                this.commentTxt = ''
                $('.idPostRep').val(0);
            }).catch(e => {
                $('#loading_show').css('display', 'none')
                this.$swal({
                    position: 'top-end',
                    icon: 'error',
                    title: e.response.data.message,
                    showConfirmButton: false,
                    timer: 3000
                })
            })
        },
        replyComment (idCm) {
            $('.inputCmIn').focus()
            $('.idPostRep').val(idCm)
        },
        handleBlur () {
            let inputValue = $('.inputCmIn').val();
            if (inputValue.trim() !== '') {
                return;
            }
            if (event.relatedTarget && event.relatedTarget.id === 'sendButton') {
                return;
            }
            $('.idPostRep').val(0);
        },
        deleteComment (idPost, idComment, replyId) {
            $('.loading_show').show().fadeIn(10)
            if(replyId == 0){
                this.idReply = Number(idComment)
            }else{
                this.idReply = replyId
            }
            this.axios.post(this.api_deletePt,
                {
                    comment_id: this.idReply
                },
                {
                    headers: {
                        Authorization: 'Bearer ' + this.token
                    }
                }
            ).then((response) => {
                $('#loading_show').css('display', 'none')
                this.other = response
                const index = this.listPost.findIndex(post => post.id == idPost);
                if (index !== -1) {
                    const postIndexCm = this.listPost[index].comment.findIndex(post => post.id == idComment);
                    if (postIndexCm !== -1) {
                        if (replyId == 0) {
                            this.listPost[index].comment.splice(postIndexCm, 1);
                        }else{
                            const postIndexRl = this.listPost[index].comment[postIndexCm].reply.findIndex(post => post.id == replyId);
                            if (postIndexRl !== -1) {
                                this.listPost[index].comment[postIndexCm].reply.splice(postIndexRl, 1);
                            }
                        }
                    }
                }
            }).catch(e => {
                $('#loading_show').css('display', 'none')
                this.$swal({
                    position: 'top-end',
                    icon: 'error',
                    title: e.response.data.message,
                    showConfirmButton: false,
                    timer: 3000
                })
            })
        },
        getListPost() {
            if (!this.hasMore || this.isLoading) return;
            this.isLoading = true;
            this.axios.get(`${this.api_listPost}?limit=${this.limit}&page=${this.page}`, {
                headers: { Authorization: "Bearer " + this.token }
            }).then((response) => {
                const newPosts = response.data.data.list_posts;
                if (newPosts.length > 0) {
                    this.listPost.push(...newPosts);
                    this.page++;
                } else {
                    this.hasMore = false;
                }
            }).catch((error) => {
                this.other = error;
            }).finally(() => {
                this.isLoading = false;
            });
        },
        handleScroll() {
            const scrollY = window.scrollY || document.documentElement.scrollTop;
            const windowHeight = window.innerHeight;
            const documentHeight = document.documentElement.scrollHeight;
            if (scrollY + windowHeight >= documentHeight - 50) {
                this.getListPost();
            }
        },
        formatTime(dateString) {
            if (!dateString) return "";
            const date = new Date(dateString.replace(" ", "T"));
            const now = new Date();
            const diffMs = now - date; // Chênh lệch thời gian (milliseconds)
            const diffMinutes = Math.floor(diffMs / 60000);
            const diffHours = Math.floor(diffMinutes / 60);

            // Nếu trong cùng ngày
            if (date.toDateString() === now.toDateString()) {
                if (diffMinutes < 1) return "Vừa xong";
                if (diffMinutes < 60) return `${diffMinutes} phút trước`;
                return `${diffHours} giờ trước`;
            }

            // Nếu quá ngày hôm nay → hiển thị dạng "12 tháng 2 lúc 16:58"
            const day = date.getDate();
            const month = date.getMonth() + 1;
            const hours = date.getHours().toString().padStart(2, "0");
            const minutes = date.getMinutes().toString().padStart(2, "0");

            return `${day} tháng ${month} lúc ${hours}:${minutes}`;
        },
        likePost (postId) {
            this.axios.post(this.api_like,
                {
                    post_id: postId
                },
                {
                    headers: {
                        Authorization: 'Bearer ' + this.token
                    }
                }
            ).then((response) => {
                const updatedPost = response.data.data;
                const index = this.listPost.findIndex(post => post.id === updatedPost.post_id)
                if (index !== -1) {
                    let updatedLikedUsers = [...this.listPost[index].liked_users];
                    if (updatedPost.status === 1) {
                        updatedLikedUsers.push({ id: updatedPost.user_id, name: this.fullname, image: this.img });
                    } else {
                        updatedLikedUsers = updatedLikedUsers.filter(user => user.id !== updatedPost.user_id);
                    }
                    this.listPost[index] = { 
                        ...this.listPost[index], 
                        number_like: updatedPost.number_like,
                        liked_users: updatedLikedUsers
                    };
                }
            })
        },
        getListInfo () {
            this.axios.get(this.api_infouser, {
                headers: {
                    Authorization: 'Bearer ' + window.localStorage.getItem('token')
                }
            }).then((response) => {
                this.fullname = response.data.data.user.fullname
            }).catch(e => {
                if (e.response.status === 401) {
                    setTimeout(function() {
                        this.axios.post(this.api_logout, {
                        }).then(res => {
                            if (res.data.success === true) {
                                localStorage.clear();
                            }
                        })
                    }.bind(this), 5000);
                    setTimeout(function() {
                        window.location.href = '/'
                    }, 13000);
                }
            })
        },
        handleFiles(event) {
            let files = event.target.files;
            if (files.length) {
                this.imageList = Array.from(files).map((file) => ({
                    file,
                    preview: URL.createObjectURL(file)
                }));
            }
        },
        removeImage(index) {
            this.imageList.splice(index, 1);
            this.updateFileInput();
        },
        updateFileInput() {
            let fileInput = document.getElementById("imageUpload");
            if (fileInput) {
                let dataTransfer = new DataTransfer();
                this.imageList.forEach((img) => dataTransfer.items.add(img.file));
                fileInput.files = dataTransfer.files;
            }
        },
        handleVideos(event) {
            let files = event.target.files;
            if (files.length) {
                this.videoList = Array.from(files).map((file) => ({
                    file,
                    preview: URL.createObjectURL(file)
                }));
            }
        },
        removeVideo(index) {
            this.videoList.splice(index, 1);
            this.updateFileInputvd();
        },
        updateFileInputvd() {
            let fileInput = document.getElementById("videoUpload");
            if (fileInput) {
                let dataTransfer = new DataTransfer();
                this.videoList.forEach((img) => dataTransfer.items.add(img.file));
                fileInput.files = dataTransfer.files;
            }
        },
        addPost (e) {
            e.preventDefault()
            $('.loading_show').show().fadeIn(10)
            const form = new FormData();
            form.append('content', this.content);
            let file_imgAp = document.getElementById('imageUpload').files
            let file_videoAp = document.getElementById('videoUpload').files
            if (file_imgAp && file_imgAp.length > 0) {
                for (let i = 0; i < file_imgAp.length; i++) {
                    form.append('images[]', file_imgAp[i]);
                }
            }
            if (file_videoAp && file_videoAp.length > 0) {
                for (let i = 0; i < file_videoAp.length; i++) {
                    form.append('video[]', file_videoAp[i]);
                }
            }
            this.axios.post(this.api_addpost, 
                form,
                {
                    headers: { Authorization: "Bearer " + this.token }
                }
            ).then(res => {
                $('#loading_show').css('display', 'none')
                if(this.img == 'null'){
                    this.imgaddUserpost = 'upload/post_fe/images/female-avatar.jpg'
                }else{
                    this.imgaddUserpost = this.img
                }
                let arrPost = {
                    id: res.data.data.id,
                    user_id: Number(this.iduser),
                    content: res.data.data.content,
                    video: res.data.data.video,
                    images: res.data.data.images,
                    status: 1,
                    fe_del: 1,
                    created_at: res.data.data.created_at,
                    updated_at: res.data.data.created_at,
                    type_mes: 1,
                    number_like: 0,
                    fullname: this.fullname,
                    hinh_anh: this.imgaddUserpost,
                    liked_users: [],
                    comment: []
                }
                this.listPost.unshift(arrPost);
                this.$swal({
                    position: 'top-end',
                    icon: 'success',
                    title: res.data.message,
                    showConfirmButton: false,
                    timer: 4000
                })
                this.content = ''
                this.imageList = []
                this.videoList = []
                this.showmodal = false
            }).catch(e => {
                $('#loading_show').css('display', 'none')
                this.$swal({
                    position: 'top-end',
                    icon: 'error',
                    title: e.response.data.message,
                    showConfirmButton: false,
                    timer: 3000
                })
            })
        },
        showMdClick () {
            this.showmodal = true
        },
        imagesArray(listIm) {
            return listIm.split(";").filter(img => img.trim() !== "");
        },
        isLikedByUser (post) {
            return post.liked_users.some(user => user.id == this.iduser);
        },
        toggleContent(index) {
            this.openIndex = this.openIndex === index ? null : index;
        },
        deletePost (postId) {
            this.isOpen = false
            this.openIndex = null
            $('.loading_show').show().fadeIn(10)
            this.axios.post(this.api_deletePt,
                {
                    post_id: postId
                },
                {
                    headers: {
                        Authorization: 'Bearer ' + this.token
                    }
                }
            ).then((response) => {
                $('#loading_show').css('display', 'none')
                // this.listPost = this.listPost.filter(post => post.id != postId);
                const index = this.listPost.findIndex(post => post.id == postId);
                if (index !== -1) {
                    this.listPost.splice(index, 1);
                }
                this.$swal({
                    position: 'top-end',
                    icon: 'success',
                    title: response.data.message,
                    showConfirmButton: false,
                    timer: 3000
                })
            }).catch(e => {
                $('#loading_show').css('display', 'none')
                this.$swal({
                    position: 'top-end',
                    icon: 'error',
                    title: e.response.data.message,
                    showConfirmButton: false,
                    timer: 3000
                })
            })
        },
        // formatNumber(value) {
        //     return new Intl.NumberFormat("de-DE").format(value);
        // },
        formatNumber(value) {
            if (value < 1000) return value.toString();
            return (value / 1000).toFixed(1).replace(".0", "") + "k";
        }
    }
}
</script>
<style>
</style>
