<template>
    <footer id="footer" class="footer-wrapper">
        <!-- <div class="parent" v-if="rank >= 4">
            <router-link class="nav-item" to='/idol-show'>
                <Vue3DraggableResizable
                    :initW="200"
                    :initH="143"
                    v-model:x="x"
                    v-model:y="y"
                    v-model:w="w"
                    v-model:h="h"
                    v-model:active="active"
                    :draggable="true"
                    :resizable="false"
                >
                </Vue3DraggableResizable>
            </router-link>
        </div> -->
        <!-- <div class="banner_live_partner">
            <a href="javascript:;"><img src="../assets/images/okvip.png" alt=""></a>
        </div> -->
        <div class="wrapper_fter_end">
            <div class="container ner_responesize">
                <div class="preview_okvip">
                    <div class="img_f8bet">
                        <img src="../assets/images/logof8.png" alt="">
                    </div>
                    <div class="textfooter">
                        <p v-if="!readMore[1]">{{ content.substring(0, 550) + '[...]' }} <span @click="showMore(1)" class="spanmore">Xem thêm</span></p>
                        <p v-if="readMore[1]">{{ content }} <span @click="showLess(1)" class="spanless">Thu gọn</span></p>
                    </div>
                </div>
                <h2 class="title_infomation">Thông tin</h2>
                <div class="row">
                    <div class="col-md-3 col-sm-3 col-xs-3 item_footer">
                        <div class="menu_footer_item">
                            <p><a :href="link_banner" target="_blank">Điều khoản và điều kiện</a></p>
                            <p><a :href="link_banner" target="_blank">Giới thiệu về 78win</a></p>
                        </div>
                    </div>
                    <div class="col-md-3 col-sm-3 col-xs-3 item_footer">
                        <div class="menu_footer_item">
                            <p><a :href="link_banner" target="_blank">Chơi có trách nhiệm</a></p>
                            <p><a :href="link_banner" target="_blank">Miễn trách nhiệm</a></p>
                        </div>
                    </div>
                    <div class="col-md-3 col-sm-3 col-xs-3 item_footer">
                        <div class="menu_footer_item">
                            <p><a :href="link_banner" target="_blank">Quyền riêng tư tại 78win</a></p>
                            <p><a :href="link_banner" target="_blank">Những câu hỏi thường gặp</a></p>
                        </div>
                    </div>
                    <div class="col-md-3 col-sm-3 col-xs-3 item_footer">
                        <div class="menu_footer_item">
                            <p><a :href="link_banner" target="_blank">Hướng dẫn nạp tiền</a></p>
                            <p><a :href="link_banner" target="_blank">Hướng dẫn rút tiền</a></p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="copyright_f8">
                <p>Copyright © F8game.live Reserved</p>
            </div>
        </div>
        <div class="parent" v-if="token != null && listPost.length != 0" @click="showModalText=true">
            <Vue3DraggableResizable
                :initW="170"
                :initH="170"
                v-model:x="x"
                v-model:y="y"
                v-model:w="w"
                v-model:h="h"
                v-model:active="active"
                :draggable="true"
                :resizable="false"
            >
            </Vue3DraggableResizable>
        </div>
        <!-- modal post -->
            <div id="modal-example" class="modal-login">
                <div class="overlay" v-if="showModalText" @click="showModalText = false"></div>
                <div class="modal" v-if="showModalText">
                    <div class="wp-modal-register">
                        <div class="header-modal">
                            <button class="close-modal_login" @click="showModalText = false"><img src="../assets/images/close_md.png" alt=""></button>
                        </div>
                        <div class="content-modal_tab">
                            <div>
                                <div class="wp_text_post_md">
                                    <div class="textpost" v-for="(item, index) in listPost.slice(0,1)" :key="index">
                                        <h2><span>{{ item.title }}</span> <button class="dkngay_idol" @click="dkgIdol">Đăng ký ngay</button></h2>
                                        <div class="wp_text_post">
                                            <video controls :src="link_web + item.video"></video>
                                            <div class="htmltext" v-html="item.content"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        <!-- end modal post -->
        <!-- modal register -->
            <div id="modal-example" class="modal-login">
                <div class="overlay" v-if="showModal_rg" @click="showModal_rg = false"></div>
                <div class="modal" v-if="showModal_rg">
                    <div class="wp-modal-register">
                        <div class="header-modal">
                            <button class="close-modal_login" @click="showModal_rg = false"><img src="../assets/images/close_md.png" alt=""></button>
                        </div>
                        <div class="content-modal_tab">
                            <div>
                                <div class="logo_modal_login">
                                    <div class="logo_mdal">
                                        <div class="img_md">
                                            <img src="../assets/images/logomd.png" alt="">
                                        </div>
                                        <div class="img_md1">
                                            <img src="../assets/images/logomd1.png" alt="">
                                        </div>
                                    </div>
                                    <!-- <div class="img_pathner_md">
                                        <img src="../assets/images/logomd2.png" alt="">
                                    </div> -->
                                </div>
                                <div class="form_dangky">
                                    <form action="" method="post" id="frm_register" @submit="registerIdol">
                                        <div class="row">
                                            <div class="col-md-6 col-sm-6 col-xs-12 left_frm_register">
                                                <div class="wrapper_left_register">
                                                    <div class="input_dk">
                                                        <label><img src="../assets/images/us1.png" alt=""> Tên đăng nhập (*)</label>
                                                        <input type="text" v-model="username" placeholder="Tên đăng nhập">
                                                    </div>
                                                    <div class="input_dk">
                                                        <label><img src="../assets/images/ht.png" alt=""> Họ tên (*)</label>
                                                        <input type="text" v-model="name" placeholder="Họ tên">
                                                    </div>
                                                    <div class="input_dk">
                                                        <label><img src="../assets/images/sex.png" alt="Giới tính"> Giới tính (*)</label>
                                                        <Multiselect
                                                            v-model="sex"
                                                            :options="{
                                                                0: 'Nữ',
                                                                1: 'Nam',
                                                                2: 'Khác'
                                                            }"
                                                            placeholder="Giới tính"
                                                        />
                                                    </div>
                                                    <div class="input_dk">
                                                        <label><img src="../assets/images/em.png" alt=""> Email (*)</label>
                                                        <input type="email" v-model="email" placeholder="Email">
                                                    </div>
                                                    <div class="input_dk">
                                                        <label><img src="../assets/images/dt.png" alt=""> Số điện thoại (*)</label>
                                                        <input type="text" v-model="phone" placeholder="Số điện thoại">
                                                    </div>
                                                    <div class="input_dk">
                                                        <label><img src="../assets/images/bank.png" alt=""> Tên ngân hàng</label>
                                                        <input type="text" v-model="bank" placeholder="Tên ngân hàng">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-6 col-sm-6 col-xs-12 right_frm_register">
                                                <div class="wrapper_left_register">
                                                    <div class="input_dk">
                                                        <label><img src="../assets/images/nbb.png" alt=""> Số tài khoản</label>
                                                        <input type="text" v-model="numberbank" placeholder="Số tài khoản">
                                                    </div>
                                                    <div class="input_dk">
                                                        <label><img src="../assets/images/csh.png" alt=""> Tên chủ tài khoản</label>
                                                        <input type="text" v-model="owner" placeholder="Tên chủ tài khoản">
                                                    </div>
                                                    <div class="input_dk">
                                                        <label><img src="../assets/images/ll.png" alt=""> Loại live (*)</label>
                                                        <Multiselect
                                                            v-model="type"
                                                            v-bind="typeList"
                                                            placeholder="Loại live"
                                                            track-by="name"
                                                        />
                                                    </div>
                                                    <div class="input_dk">
                                                        <label><img src="../assets/images/pic.png" alt=""> Hình ảnh (*)</label>
                                                        <input type="file" accept="image/*" name="chose_picture" class="chose_picture inp_hidden" id="chose_picture" />
                                                        <label class="chose_picture chosefile" for="chose_picture">
                                                            <font-awesome-icon icon="fa-solid fa-image" />
                                                            <span>Chọn ảnh</span>
                                                        </label>
                                                    </div>
                                                    <div class="input_dk">
                                                        <label><img src="../assets/images/videos.png" alt=""> Video (*)</label>
                                                        <input type="file" accept="video/mp4,video/x-m4v,video/*" name="chose_video" class="chose_video inp_hidden" id="chose_video" />
                                                        <label class="chose_video chosefile" for="chose_video">
                                                            <font-awesome-icon icon="fa-solid fa-video" />
                                                            <span>Chọn video</span>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="btn_dangky">
                                            <button type="submit">Đăng ký ngay</button>
                                            <button type="button" style="display:none;">Đăng nhập</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        <!-- end modal register -->
    </footer>
    
</template>

<script>
import $ from 'jquery'
import Multiselect from '@vueform/multiselect'
$( document ).ready(function() {
    $(document).on('change', '.chose_picture', function() {
        $('label[for="chose_picture"] span').text($(this).val());
    });$(document).on('change', '.chose_video', function() {
        $('label[for="chose_video"] span').text($(this).val());
    });
});
export default {
    name: 'Footer_live',
    data () {
        return {
            rank: window.localStorage.getItem('rank'),
            token: window.localStorage.getItem('token'),
            readMore: [false],
            content: '78win là một nhà cái có giấy phép cá cược trực tuyến hợp pháp do Isle of Man và Khu kinh tế Cagayan and Freeport cấp. Với bề dày kinh nghiệm và danh tiếng phục vụ hơn 10 triệu người chơi, 78win đã và đang khẳng định vị thế của mình trên thị trường game trực tuyến .Với tư cách là một công ty trò chơi trực tuyến có trụ sở tại Luân Đôn, Anh, sở hữu đội ngũ nhân tài chuyên nghiệp đông đảo cung cấp sản phẩm phục vụ chất lượng cao. 78win đảm bảo không tiết lộ thông tin cá nhân khách hàng cho bất kỳ bên thứ ba nào , sử dụng tiêu chuẩn mã hoá dữ liệu ở mức cao nhất. Tất cả thông tin cá nhân đều được thông qua hệ thống bảo mật - Secure Socket (Chuẩn mã hóa SS 128-bit), đồng thời được bảo vệ trong môi trường quản lý an toàn đảm bảo không thể truy cập từ các khu vực mạng công cộng. Tất cả dữ liệu ra vào đều bị hạn chế, giám sát nghiêm ngặt và quản lý chặt chẽ nhằm mang đến cho người chơi trải nghiệm người dùng an toàn tuyệt đối.',
            showModal_rg: false,
            username: '',
            name: '',
            sex: '',
            email: '',
            phone: '',
            bank: '',
            numberbank: '',
            owner: '',
            type: [],
            showModalText: false,
            listPost: [],

            typeList: {
                mode: 'tags',
                closeOnSelect: false,
                options: [
                    { value: '1', label: 'Live thường' },
                    { value: '2', label: 'Live 18+' },
                    { value: '3', label: 'Live chat' },
                ],
                createOption: true
            },
        }
    },
    created() {
        this.getListPost()
    },
    components: {
        Multiselect
    },
    methods: {
        showMore(index) {
            this.readMore[index] = true;
        },
        showLess(index) {
            this.readMore[index] = false;
        },
        registerIdol (e) {
            e.preventDefault()
            $('.loading_show').show().fadeIn(10)
            let file_img = document.getElementById('chose_picture').files[0]
            let file_video = document.getElementById('chose_video').files[0]
            const form = new FormData();
            form.append('username', this.username);
            form.append('name', this.name);
            form.append('sex', this.sex);
            form.append('email', this.email);
            form.append('phone', this.phone);
            form.append('bank_name', this.bank);
            form.append('bank_account', this.numberbank);
            form.append('bank_owner', this.owner);
            form.append('type_live', this.type);
            form.append('hinh_anh', file_img);
            form.append('video', file_video);
            this.axios.post(this.api_registerIdol, 
                form,
                {
                    headers: {
                        Authorization: 'Bearer ' + window.localStorage.getItem('token')
                    }
                }
            ).then(res => {
                $('#loading_show').css('display', 'none')
                this.$swal({
                    position: 'top-end',
                    icon: 'success',
                    title: res.data.message,
                    showConfirmButton: false,
                    timer: 4000
                })
                this.showModal_rg = false
                this.name = this.username = this.email = this.sex = this.phone = this.bank = this.numberbank = this.owner = ''
                this.type = []
                $('#chose_picture').val('')
                $('#chose_video').val('')
            }).catch(e => {
                $('#loading_show').css('display', 'none')
                if (e.response.status === 404) {
                    this.$swal({
                        position: 'top-end',
                        icon: 'error',
                        title: e.response.data.message,
                        showConfirmButton: false,
                        timer: 3000
                    })
                }
            })
        },
        getListPost () {
            this.axios.get(this.api_posttext).then((response) => {
                this.listPost = response.data.data.list_data
            })
        },
        dkgIdol () {
            this.showModalText = false
            this.showModal_rg = true
        },
    }
}
</script>
<style src="@vueform/multiselect/themes/default.css"></style>
